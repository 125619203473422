<template>
  <section class="about" style="background: #FFF;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner-s"
      >
        <v-layout
          class="hidden-sm-and-down"
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="tnf-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                  :to="'/' + menu.key"
                >
                  {{menu.name}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
      </v-img>
      <v-container  grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12 class="tnf-top-div">
            <h2>Company Profile</h2>
            <h4>公司介绍</h4>
            <h5> </h5>
            <h3>欧洲高端门窗及遮阳产品品牌</h3>
            <v-layout wrap>
              <v-flex xs12 sm12 md7 lg7>
                <p>法国TRYBA品牌成立于1979年。主要生产门窗及铝合金卷帘。目前在法国、德国、瑞士、比利时、奥地利等国家拥有20个生产基地。在法国有超过260家展示厅。每天生产近4500个门窗和卷帘。全球约1900名员工。营业额超过5亿欧元。特诺发（上海）窗业有限公司提供设计、生产、销售以及售后等全方位服务，是门窗与外遮阳系统整体方案解决服务商。融合了法国的优雅与德国的工艺，继承欧洲精良工艺的同时，针对中国建筑，在应用上进行本地化再研发，确保成品完全适用国内建筑，质量标准和技术与欧洲同步。</p>
              </v-flex>
              <v-flex xs12 sm12 md5 lg5 pl-lg-12>
                <v-img
                  height="40"
                  src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/about_logo.png"
                  aspect-ratio="1"
                  contain
                  position="left"
                ></v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    <v-layout wrap style="margin-top: 30px;background: #0C65A5;">
      <v-container  grid-list-md>
        <v-flex xs12 sm12 md12 lg12 class="tnf-middle-div">
          <h6>欧洲高级门窗遮阳技术</h6>
          <h3>一切都始于TRYBA</h3>
          <v-layout wrap>
            <v-flex xs12 sm12 md7 lg7>
              <p>特诺发专注建筑洞口一体化集成系统，隔音、隔热、降低建筑能耗、减少碳排放，改善室内声、光、热环境。依托法国特诺发及德国合作伙伴在门窗遮阳领域强大的研发技术团队。结合欧洲顶级门窗遮阳技术与中国建筑及气候特点，打造一系列适合本土市场的高性能门窗遮阳产品。</p>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 sm12 md4 lg4 pr-md-6>
              <v-img
                height="224"
                src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/about_middle.png"
                aspect-ratio="1"
                contain
                position="left"
              >
              </v-img>
              <h5 style="max-width:380px; text-align: center; height: 14px; font-size: 14px; font-weight: 400; color: #EFEFF4; line-height: 21px; margin-top: 16px; margin-bottom:50px;">TRYBA特诺发法国生产基地鸟瞰图</h5>
            </v-flex>
            <v-flex xs12 sm12 md8 lg8 mt-6>
              <h2 style="font-size: 20px; font-weight: bold; color: #FFD923; height: 24px; line-height: 24px; border-left: 8px solid #FFD923;padding-left:20px; margin-bottom: 5px;">秉持“质量无折扣”理念</h2>
              <h3 style="font-size: 20px; font-weight: bold; color: #FFD923; line-height: 30px;padding-left:28px; margin-bottom: 10px;">关注细节</h3>
              <p>公司严格执行“技术同步、材料同质、质量体系相同、品牌同源”的“四同原则”，原材料由欧洲原装进口，秉持“质量无折扣”理念，关注细节，不断提升产品品质与服务质量。</p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-container>
    </v-layout>
    <v-container  grid-list-md style="margin-top: 60px;">
      <v-layout wrap class="tnf-bottom-div">
        <v-flex xs12 sm12 md8 lg8 pr-lg-6>
          <h2>为建筑量身定制</h2>
          <h3>一体化解决方案</h3>
          <p>特诺发在色彩应用、建筑表皮构建等设计元素上给设计师提供最大化的选择，并可根据气候特点、地域文化分布为建筑量身定制一体化解决方案，让建筑升级为更优雅更具竞争力的绿色节能建筑。特诺发建立了完善的项目管理体制，拥有多个专业工程师团队和高效的生产、安装及服务队伍，为顾客提供专业的技术支持与定制服务。</p>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4>
          <v-img
            height="224"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/about_addr.png"
            aspect-ratio="1"
            contain
            position="left"
          >
          </v-img>
          <h5 style="max-width:380px; text-align: center; height: 14px; font-size: 14px; font-weight: 400; color: #0B1531; line-height: 21px; margin-top: 16px; margin-bottom:50px;">TRYBA特诺发法国生产基地鸟瞰图</h5>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12>
          <h1 class="tnf-large-text">COMPANY PROFILE</h1>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    innerWidth: window.innerWidth,
    tabSubMenu: null,
    subMenu: [
      { key: 'about', name: '公司简介' },
      { key: 'story', name: '品牌故事' },
      { key: 'history', name: '发展历程' },
      { key: 'honor', name: '企业荣誉' },
      { key: 'assistant', name: '门窗管家' },
      { key: 'partner', name: '合作伙伴' }
    ]
  }),
  created () {
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      let links = []
      if (this.$store.getters.companyInfo.links) {
        links = this.$store.getters.companyInfo.links
      }
      return {
        links: links,
        banners: banners
      }
    }
  }
}
</script>
<style lang="css">
  @import '../css/about.css';
</style>
